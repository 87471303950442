import { writable } from 'svelte/store';

export const searchStore = writable({
  query: '',
  categoryId: null,
  categoryMap: [],
  order: { value: "name", direction: "asc" },
});

export function resetSearch() {
  searchStore.set({
    query: '',
    categoryId: null,
    categoryMap: [],
    order: { value: "name", direction: "asc" },
  });
}