import { writable } from 'svelte/store';

// Function to load cart data from localStorage
function loadCart() {
  const storedCart = localStorage.getItem('cart');
  return storedCart ? JSON.parse(storedCart) : {};
}

// Function to create a writable store that syncs with localStorage
function createCartStore() {
  const { subscribe, set, update } = writable(loadCart());

  return {
    subscribe,
    set: (value) => {
      localStorage.setItem('cart', JSON.stringify(value));
      set(value);
    },
    update: (callback) => {
      update((current) => {
        const updated = callback(current);
        localStorage.setItem('cart', JSON.stringify(updated));
        return updated;
      });
    },
    // Optional: A function to clear the cart
    clear: () => {
      localStorage.removeItem('cart');
      set({});
    }
  };
}

export const cart = createCartStore();