// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBxrCMu3gvYACS2f_YfS4jgMrUBfcxRH3Y",
    authDomain: "bitrix-8f62d.firebaseapp.com",  // Corrected this line
    projectId: "bitrix-8f62d",    // Corrected this line
    storageBucket: "bitrix-8f62d.appspot.com",
    messagingSenderId: "859371925914",
    appId: "1:859371925914:web:a296c332aaff126f2a4a23",
    measurementId: "G-0KEEYBCLG4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);
