<script>
  let name = "";
  let email = "";
  let phone = ""; // Added phone variable
  let message = "";

  let sendClicked = false;

  function handleSubmit() {
    const requestData = {
      name: name,
      email: email,
      phone: phone, // Include phone in the request
      richiesta: message,
    };

    if (!name || (!email && !phone) || !message) {
      sendClicked = true;
      return;
    }

    fetch("https://bitrix-automation-13891219146.europe-west1.run.app/firebase/create-request", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        alert(`Richiesta inviata con successo!`);
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error sending request:", error);
        alert("There was an error sending your request. Please try again.");
      });
  }
</script>

<div
  class="container max-w-6xl mx-auto p-6 flex flex-col md:flex-row items-start gap-10"
>
  <!-- Contatti Card -->
  <div
    class="contacts-left bg-[#2488c9] text-white p-8 rounded-lg w-full md:w-1/3 self-center"
  >
    <h2 class="text-3xl font-bold text-center mb-4">I nostri contatti</h2>
    <div class="contacts-list">
      <p>
        <strong>Mail:</strong>
        <a
          href="mailto:mepa@elettroservizi.it"
          class="text-white hover:underline">mepa@elettroservizi.it</a
        >
      </p>
      <p>
        <strong>Tel:</strong>
        <a href="tel:+390696521637" class="text-white hover:underline"
          >+39 0696521637</a
        >
      </p>
      <p>
        <strong>PEC:</strong>
        <a
          href="mailto:elettroservizisrl@arubapec.it"
          class="text-white hover:underline">elettroservizisrl@arubapec.it</a
        >
      </p>
    </div>
  </div>

  <!-- Form Contatti -->
  <div class="form-container w-full md:w-2/3 p-8 bg-white rounded-lg">
    <h1 class="text-4xl font-bold mb-8 text-[#2488c9] text-center">
      Hai un dubbio? Scrivici!
    </h1>
    <form class="space-y-6">
      <!-- Nome Ente -->
      <div>
        <label
          for="name"
          class="block text-lg font-semibold text-gray-700"
          style={sendClicked && !name ? "color: red;" : ""}
        >
          Nome Ente*
        </label>
        <input
          type="text"
          id="name"
          bind:value={name}
          class="mt-2 block w-full shadow-sm text-lg border border-gray-300 rounded-lg p-3"
          required
          style={sendClicked && !name ? "border-color: red;" : ""}
        />
      </div>

      <!-- Email -->
      <div>
        <label
          for="email"
          class="block text-lg font-semibold text-gray-700"
          style={sendClicked && !email && !phone ? "color: red;" : ""}
        >
          Email{phone ? "" : "*"}
        </label>
        <input
          type="email"
          id="email"
          bind:value={email}
          class="mt-2 block w-full shadow-sm text-lg border border-gray-300 rounded-lg p-3"
          required
          style={sendClicked && !email && !phone ? "border-color: red;" : ""}
        />
      </div>

      <!-- Telefono -->
      <div>
        <label
          for="phone"
          class="block text-lg font-semibold text-gray-700"
          style={sendClicked && !phone && !email ? "color: red;" : ""}
        >
          Telefono{email ? "" : "*"}
        </label>
        <input
          type="text"
          id="phone"
          bind:value={phone}
          class="mt-2 block w-full shadow-sm text-lg border border-gray-300 rounded-lg p-3"
          required
          pattern="^\+?[0-9]*$"
          style={sendClicked && !phone && !email ? "border-color: red;" : ""}
        />
      </div>

      <!-- Messaggio -->
      <div>
        <label
          for="message"
          class="block text-lg font-semibold text-gray-700"
          style={sendClicked && !message ? "color: red;" : ""}
        >
          Richiesta*
        </label>
        <textarea
          id="message"
          bind:value={message}
          class="mt-2 block w-full shadow-sm text-lg border border-gray-300 rounded-lg p-3 resize-y"
          required
          style={sendClicked && !message ? "border-color: red;" : ""}
        ></textarea>
      </div>

      <!-- Submit Button -->
      <button
        on:click={handleSubmit}
        class="w-full py-4 px-6 bg-gradient-to-r from-[#2488c9] to-blue-500 text-white text-xl font-bold rounded-lg transition-transform transform hover:scale-105"
      >
        Invia
      </button>
    </form>
  </div>
</div>

<style>
  .container {
    display: flex;
    justify-content: center;
    gap: 50px;
  }

  .contacts-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
</style>
