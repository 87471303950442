<script>
  import { Link } from "svelte-routing";
  import { cart } from "../stores/stores.js";
  import { navigate } from "svelte-routing";
  import { searchStore } from "../stores/searchStore.js";

  let cart_sum = 0;
  export let searchQuery = "";
  export let selectedCategoryId = null;

  const unsubscribe = cart.subscribe((items) => {
    const itemValues = Object.values(items);
    cart_sum = 0;
    itemValues.forEach((item) => {
      cart_sum += item.count;
    });
  });

  function redirectToSearch(event) {
    searchStore.update((store) => ({
      ...store,
      query: searchQuery,
      categoryId: null,
      categoryMap: [],
    }));
    if (event.code === "Enter" || event.type === "click") {
      selectedCategoryId = null; // Reset category when searching by query
      navigate("/search", { replace: true });
    }
  }

  let isMenuOpen = false; // Stato per il toggle del menu mobile
</script>

<header class="w-screen fixed top-0 bg-white text-black shadow-lg z-50">
  <div
    class="w-full max-w-[90vw] xl:max-w-[80vw] mx-auto flex items-center justify-between py-4"
  >
    <!-- Logo -->
    <Link
      to="/"
      class="flex items-center min-w-[100px]"
      on:click={() => {
        searchQuery = "";
        searchStore.update((store) => ({
          ...store,
          categoryMap: [],
        }));
      }}
    >
      <img
        src="/img/logo.png"
        alt="Elettroservizi Logo"
        class="h-8 w-auto object-contain"
      />
    </Link>

    <!-- Barra di ricerca -->
    <div class="search-text-container max-w-[50%] flex-grow mx-4 min-w-14">
      <input
        type="text"
        class="search-input w-full"
        placeholder="Cerca prodotti..."
        bind:value={searchQuery}
        on:keypress={redirectToSearch}
        on:change={redirectToSearch}
      />
      <!-- Clear button -->
      {#if searchQuery}
        <button
          class="clear-button w-9 my-auto ml-2 cursor-pointer p-1"
          aria-label="Clear search"
          on:click={() => (searchQuery = "")}
        >
          X
        </button>
      {/if}
      <!-- Search button -->
      <img
        class="w-9 my-auto cursor-pointer border-gray-400 p-1"
        aria-label="search button"
        alt="Search"
        src="/img/svg/search.svg"
        height="20px"
        on:click={redirectToSearch}
      />
    </div>

    <!-- Carrello (spostato fuori dal menu principale) -->
    <div class="flex items-center">
      <!-- Menu hamburger per mobile -->
      <button
        on:click={() => (isMenuOpen = !isMenuOpen)}
        class="block md:hidden text-black focus:outline-none mr-4"
      >
        <!-- Icona dell'hamburger -->
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16m-7 6h7"
          ></path>
        </svg>
      </button>

      <!-- Menu principale (solo links) -->
      <ul class="hidden md:flex md:space-x-4 items-center navbar-menu mr-4">
        <li
          class="navbar-item cursor-pointer transition-colors hover:text-gray-500"
        >
          <Link to="/">Home</Link>
        </li>
        <li
          class="navbar-item cursor-pointer transition-colors hover:text-gray-500"
        >
          <Link to="/contact">Contatti</Link>
        </li>
        <li
          class="navbar-item cursor-pointer transition-colors hover:text-gray-500"
        >
          <Link to="/about">Informazioni</Link>
        </li>
      </ul>
    </div>

    <!-- Carrello (sempre visibile) -->
    <Link to="/checkout" class="relative flex items-center">
      <img
        class="shopping-cart min-w-6"
        aria-label="shopping cart"
        alt="Checkout"
        src="/img/svg/checkout.svg"
        style="width: 24px; height: 24px;"
      />
      {#if cart_sum > 0}
        <div
          class="absolute -top-1 -right-1 bg-[#20447d] text-white font-bold p-0.5 text-center leading-none text-xs w-4 h-4 rounded-full shadow-sm"
        >
          {cart_sum}
        </div>
      {/if}
    </Link>
  </div>

  <!-- Menu mobile dropdown -->
  {#if isMenuOpen}
    <ul
      class="md:hidden flex flex-col items-center space-y-2 pt-4 pb-6 bg-white shadow-lg navbar-menu"
    >
      <li
        class="navbar-item cursor-pointer transition-colors hover:text-gray-500"
      >
        <Link to="/">Home</Link>
      </li>
      <li
        class="navbar-item cursor-pointer transition-colors hover:text-gray-500"
      >
        <Link to="/contact">Contatti</Link>
      </li>
      <li
        class="navbar-item cursor-pointer transition-colors hover:text-gray-500"
      >
        <Link to="/about">Informazioni</Link>
      </li>
    </ul>
  {/if}
</header>

<style>
  /* Stile per disabilitare il disco nel menu */
  ul.navbar-menu {
    list-style: none; /* Sovrascrive `list-style: disc` dal CSS globale */
    padding-left: 0;
    margin-top: 0;
  }
  li.navbar-item {
    list-style: none; /* Sovrascrive `list-style: disc` per ogni elemento `li` */
  }

  .search-text-container {
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 10px;
  }

  .search-text-container img {
    border-left: 1px solid #dbdbdb;
    padding: 5px;
  }

  .search-text-container button {
    background-color: #ccc;
    border: none;
    border-radius: 10px;
    margin: 2px;
  }

  input {
    border: 0;
    margin: 0;
  }

  input:focus {
    outline: none;
  }

  .search-text-container {
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 2px;
    min-width: 100px;
  }

  .search-text-container img {
    border-left: 1px solid #dbdbdb;
    padding: 5px;
  }

  .search-text-container button {
    background-color: #ccc;
    border: none;
    border-radius: 10px;
    margin: 2px;
  }
</style>
