<script>
  import { get } from "svelte/store";
  import { cart } from "../stores/stores.js";
  import { navigate } from "svelte-routing"; // Use navigate for programmatic navigation
  import { currentRoute } from "../stores/pathStore.js";

  export let item;
  let { id, Images, MEPA, price, descritpion, name, category, code, count } =
    item;
  let showModal = false; // State to control modal visibility
  const cartItems = get(cart);
  count = cartItems[name] ? cartItems[name].count : 0;

  function addToCart(event) {
    event.stopPropagation(); // Prevent the event from bubbling up to the card
    count++;
    cart.update((n) => {
      return { ...n, [name]: { ...item, count: count } };
    });
  }

  const updateCart = () => {
    if (count > 0) {
      cart.update((n) => {
        return { ...n, [name]: { ...item, count: count } };
      });
    } else {
      removeItem();
    }
  };
  const countButtonHandler = (e) => {
    if (e.target.classList.contains("add")) {
      count++;
    } else if (count > 1) {
      count--;
    }
    updateCart(); // Keeps cart in sync with UI
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
    if (input !== "") {
      let newCount = parseInt(input, 10);
      if (!isNaN(newCount) && newCount > 0) {
        count = newCount;
      } else {
        // Resets count to minimum valid value if invalid input detected
        count = count < 1 ? 1 : count;
      }
      updateCart();
    }
  };

  const confirmRemoveItem = () => {
    showModal = true; // Show the modal
  };

  const removeItem = () => {
    cart.update((n) => {
      delete n[name];
      return { ...n };
    });
    count = 0;
    showModal = false; // Hide the modal after removal
  };

  const handleModalResponse = (response) => {
    if (response) {
      removeItem(); // Remove item if user confirms
    }
    showModal = false; // Hide the modal in both cases
  };

  function formatPrice(priceString) {
    const priceNumber = parseFloat(priceString);
    const formattedPrice = new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(priceNumber);

    // Sposta il simbolo dell'euro prima del numero
    return "€ " + formattedPrice.replace("€", "").trim();
  }

  function navigateToDetail() {
    currentRoute.set(`/details/${id}`);
    navigate(`/details/${id}`); // Use navigate for navigation using the ID
  }

  let showCopiedText = false; // Local state for showing the copied text
  const handleCopy = () => {
    navigator.clipboard.writeText(MEPA);
    showCopiedText = true; // Show the copied text
    setTimeout(() => (showCopiedText = false), 1000); // Hide after 1 second
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions "floral-iphone-cases.jpg" -> {img} -->
<div
  class="h-[350px] w-[82%] md:w-auto md:min-h-[400px] flex flex-col justify-between md:max-w-md p-4 sm:p-6 bg-white border border-[#2488c9] shadow hover:shadow-lg transition-shadow duration-200 box-content"
>
  <img
    src={Images}
    alt={name}
    class="w-full md:h-48 h-24 object-contain rounded-t-lg cursor-pointer"
    on:click={navigateToDetail}
  />
  <div class="flex flex-col flex-grow mt-4 justify-between">
    <div class="top-card flex flex-col flex-grow">
      <div class="flex items-center relative">
        <p
          class="text-sm sm:text-base py-2 font-bold text-[#2488c9]
           {!(MEPA == null || MEPA == '' || MEPA == undefined)
            ? 'hover:text-blue-600 cursor-pointer'
            : 'cursor-default'}"
          on:click={!(MEPA == null || MEPA == "" || MEPA == undefined)
            ? handleCopy
            : null}
        >
          Cod. MePA:<br/>{!(MEPA == null || MEPA == "" || MEPA == undefined)
            ? MEPA
            : "Da Inserire"}
        </p>
        {#if !(MEPA == null || MEPA == "" || MEPA == undefined)}
          <img
            src="/img/svg/copy-icon.svg"
            alt="Copy"
            class="sm:ml-2 ml-1 w-3 h-3 md:w-5 h-5 cursor-pointer hover:opacity-75"
            on:click={handleCopy}
          />
        {/if}
      </div>
      <h3
        class="text-sm md:text-base font-semibold text-gray-900 cursor-pointer truncate-special"
        on:click={navigateToDetail}
      >
        {name}
      </h3>
    </div>
    <div class="price-and-buttons mt-auto">
      <div
        class="text-lg font-bold text-gray-800 mt-2 w-full flex justify-start items-center"
      >
        <span class="mr-2 text-sm sm:text-base">{formatPrice(price)}</span>
        <span class="text-xs sm:text-sm text-gray-600 font-normal"
          >IVA Esclusa</span
        >
      </div>
      <div class="mt-2">
        {#if count > 0}
          <div class="flex items-center">
            <!-- Bottone "-" -->
            <button
              class="block m-0 text-sm shadow-md py-0.5 px-1 rounded-l-md border border-gray-500 focus:outline-none active:outline-none w-6"
              on:click={countButtonHandler}
            >
              -
            </button>

            <!-- Input del contatore -->
            <input
              type="number"
              class="text-center block w-10 m-0 text-sm shadow-md border border-gray-500 py-0.5 px-1 bg-gray-50 border-l-0 border-r-0"
              bind:value={count}
              on:input={handleInputChange}
              min="1"
              step="1"
            />

            <!-- Bottone "+" -->
            <button
              class="add block m-0 text-sm shadow-md border border-gray-500 py-0.5 px-1 rounded-r-md focus:outline-none active:outline-none w-6"
              on:click={countButtonHandler}
            >
              +
            </button>

            <!-- Bottone "Rimuovi" o "X" -->
            <button
              class="ml-2 flex items-center justify-center sm:ml-2 text-xs sm:text-sm shadow-md p-1 rounded-md bg-red-600 text-white focus:outline-none active:outline-none
              w-6 h-6 sm:w-auto sm:h-auto sm:px-2 sm:py-1 sm:pl-2 sm:rounded-lg"
              on:click={() => handleModalResponse(true)}
            >
              <span class="hidden sm:inline">Rimuovi</span>
              <span class="sm:hidden">✕</span>
            </button>
          </div>
        {:else}
          <button
            on:click={addToCart}
            class="add flex items-center justify-center w-full py-1 mt-2 text-xs md:text-sm bg-gradient-to-r font-semibold from-[#2488c9] to-blue-500 text-white rounded-lg shadow-md hover:from-blue-600 hover:to-indigo-700 transition-transform transform hover:scale-105 sm:self-stretch disabled:bg-gray-400 disabled:cursor-not-allowed"
          >
            Aggiungi al preventivo
          </button>
        {/if}
      </div>
    </div>
  </div>
</div>

<!--

<div class="flex flex-col items-center justify-center p-4 border rounded-lg shadow-md">

  <div class="flex justify-center items-center mb-4">
      <img class="w-48 h-48 object-cover" src="https://m.media-amazon.com/images/I/71SuEcCv6oL._AC_SL1500_.jpg" alt="Immagine non disponibile">
  </div>

  <div class="text-center">
      <p class="text-sm font-medium text-gray-600">KK6ZYTD6</p>
      <p class="text-lg font-semibold text-gray-800 truncate" title="AGPTEK, paracolpi elastici in gomma trasparente, 222 pezzi, per mobili,...">AGPTEK, paracolpi elastici in gomma trasparente, 222 pezzi, per mobili,...</p>
      <p class="text-sm text-gray-500">Agptek</p>
      <div class="text-lg font-bold text-gray-800 mt-2">22€ <span class="text-sm text-gray-600">IVA Esclusa</span></div>
  </div>

  <div class="mt-4">
      <button class="px-4 py-2 bg-purple-600 text-white font-semibold rounded-lg hover:bg-purple-700">AGGIUNGIAL PREVENTIVO</button>
  </div>
</div>

-->

<style>
  /* Hide HTML5 Up and Down arrows on input of type number */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }

  .truncate-special {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Limit to 4 lines */
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
