<script>
  import { navigate } from "svelte-routing"; // Use navigate for programmatic navigation
  import { searchStore } from "../stores/searchStore.js";
  import { onMount } from "svelte";
  import { doc, getDoc } from "firebase/firestore";
  import { db } from "../firebase";
  import { currentRoute } from "../stores/pathStore.js";

  export let name;
  export let image;
  export let productId = "";
  export let category = "";

  let categoriesMap = [];
  let categoriesData = [];

  function navigateToDetail() {
    if (category) {
      searchStore.update((store) => ({
        ...store,
        query: category,
        categoryId: category,
        categoryMap: categoriesMap,
      }));
      currentRoute.set("/search");
      navigate("/search", { replace: true });
    } else {
      navigate(`/product/${productId}`);
    }
  }

  function searchInTree(category, tree) {
    if (tree) {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].NAME === category) {
          categoriesMap.push(tree[i].NAME);
          return true;
        }
        categoriesMap.push(tree[i].NAME);
        let found = searchInTree(category, tree[i].children);
        if (found) {
          return true;
        } else {
          categoriesMap.pop();
        }
      }
    }
  }

  onMount(async () => {
    if (category) {
      const categoriesRef = doc(db, "Elettroservizi", "CategoriesTree");
      const docSnapshot = await getDoc(categoriesRef);
      if (docSnapshot.exists()) {
        categoriesData = docSnapshot.data().categories;
      } else {
        console.error("No categories found!");
      }

      searchInTree(category, categoriesData);
    }
  });
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions "floral-iphone-cases.jpg" -> {img} -->
<div
  class="bg-white border border-[#2488c9] shadow text-center hover:shadow-lg md:block transition-shadow duration-200 box-content cursor-pointer p-1 md:p-2 {(name == "Stampanti")?"hidden":""}"
  on:click={navigateToDetail}
>
  <img
    src={image}
    alt={name}
    class="w-28 h-28 object-contain rounded-t-lg mx-auto"
  />
  <div class="flex flex-col flex-1 mt-3 md:justify-between">
    <div class="top-card">
      <h3 class="lg:text-base md:text-xs text-center sm:text-base text-xs font-semibold text-gray-900">
        {name}
      </h3>
    </div>
  </div>
</div>

<!--

<div class="flex flex-col items-center justify-center p-4 border rounded-lg shadow-md">

  <div class="flex justify-center items-center mb-4">
      <img class="w-48 h-48 object-cover" src="https://m.media-amazon.com/images/I/71SuEcCv6oL._AC_SL1500_.jpg" alt="Immagine non disponibile">
  </div>

  <div class="text-center">
      <p class="text-sm font-medium text-gray-600">KK6ZYTD6</p>
      <p class="text-lg font-semibold text-gray-800 truncate" title="AGPTEK, paracolpi elastici in gomma trasparente, 222 pezzi, per mobili,...">AGPTEK, paracolpi elastici in gomma trasparente, 222 pezzi, per mobili,...</p>
      <p class="text-sm text-gray-500">Agptek</p>
      <div class="text-lg font-bold text-gray-800 mt-2">22€ <span class="text-sm text-gray-600">IVA Esclusa</span></div>
  </div>

  <div class="mt-4">
      <button class="px-4 py-2 bg-purple-600 text-white font-semibold rounded-lg hover:bg-purple-700">AGGIUNGIAL PREVENTIVO</button>
  </div>
</div>

-->

<style>
  /* Hide HTML5 Up and Down arrows on input of type number */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
</style>
