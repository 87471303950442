<script>
  import { Link } from "svelte-routing"; // Import Link for navigation
</script>

<footer class="bg-gray-800 text-white py-8">
  <div class="container mx-auto px-4">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
      
      <!-- Logo Column -->
      <div class="flex items-center">
        <Link to="/">
          <img
            src="/img/logo.png"
            alt="Elettroservizi Logo"
            class="h-12"
          />
        </Link>
      </div>

      <!-- Contact Information -->
      <div>
        <h2 class="text-lg font-semibold mb-4">Contatti</h2>
        <p>
          <strong>Mail:</strong> <a href="mailto:mepa@elettroservizi.it" class="text-blue-400 hover:underline">mepa@elettroservizi.it</a>
        </p>
        <p>
          <strong>Tel:</strong> <a href="tel:+390696521637" class="text-blue-400 hover:underline">+39 0696521637</a>
        </p>
        <p>
          <strong>PEC:</strong> <a href="mailto:elettroservizisrl@arubapec.it" class="text-blue-400 hover:underline">elettroservizisrl@arubapec.it</a>
        </p>
      </div>

      <!-- Address Information -->
      <div>
        <h2 class="text-lg font-semibold mb-4">Indirizzo</h2>
        <p>
          <strong>Via:</strong> Largo Piero Bargellini 53
        </p>
        <p>
          <strong>Cap:</strong> 00142, Roma, Italia
        </p>
        <p>
          <strong>P.IVA - C.F.:</strong> IT06978581004
        </p>
      </div>
    </div>

    <div class="mt-8 text-center text-sm">
      © {new Date().getFullYear()} Elettroservizi. All rights reserved.
    </div>
  </div>
</footer>
