<script>
  import Card from "./Card.svelte";
  import items from "../items.js";
  import { onMount } from 'svelte';
  import { db } from '../firebase';
  import { collection, getDocs } from 'firebase/firestore';
  

  export let homepage = false;
  export let categorySelected = '';
  let filteredItems = [];
  let isLoading = true;


  
  onMount(async () => {
    if(homepage){
      let productsQuery = collection(db, 'Elettroservizi', 'HomepageProducts', 'Items');
      const querySnapshot = await getDocs(productsQuery);
      const results = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      filteredItems = results;
      isLoading = false; // Stop loading once data is fetched
    }
  });
</script>

<section class="w-full mx-auto my-[2vh] grid justify-items-center md:justify-items-stretch 
                xl:grid-cols-3 
                lg:grid-cols-3 
                md:grid-cols-2 
                sm:grid-cols-2
                grid-cols-1">

  {#if isLoading}
    <!-- Show loading skeletons while data is being fetched -->
    {#each Array(8) as _}
      <div class="flex flex-col justify-between max-w-sm p-6 bg-gray-200 border border-gray-300 shadow rounded-lg animate-pulse">
        <div class="w-full h-48 bg-gray-300 rounded-t-lg"></div>
        <div class="mt-4 space-y-2">
          <div class="h-6 bg-gray-300 rounded w-3/4"></div>
          <div class="h-6 bg-gray-300 rounded w-2/4"></div>
          <div class="h-8 bg-gray-300 rounded w-1/2"></div>
        </div>
      </div>
    {/each}
  {:else}
    {#each filteredItems as item}
      <Card {item} />
    {/each}
  {/if}

</section>
