<script>
  import { navigate } from "svelte-routing";
  import { cart } from "../stores/stores.js";
  export let item;
  let { id, Images, MEPA, price, descritpion, name, category, code, count } =
    item;
  let showModal = false; // State to control modal visibility

  function formatPrice(priceString) {
    const priceNumber = parseFloat(priceString);
    const formattedPrice = new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(priceNumber);

    // Sposta il simbolo dell'euro prima del numero
    return "€" + formattedPrice.replace("€", "").trim();
  }

  const countButtonHandler = (e) => {
    if (e.target.classList.contains("add")) {
      count++;
    } else if (count > 1) {
      // Allow decrement only if count is greater than 1
      count--;
    }
    updateCart();
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
    if (input !== "") {
      let newCount = parseInt(input, 10);
      if (!isNaN(newCount) && newCount > 0) {
        count = newCount;
      } else {
        // Resets count to minimum valid value if invalid input detected
        count = count < 1 ? 1 : count;
      }
      updateCart();
    }
  };

  const updateCart = () => {
    if (count > 0) {
      cart.update((n) => ({ ...n, [name]: { ...n[name], count } }));
    } else {
      removeItem();
    }
  };

  const confirmRemoveItem = () => {
    showModal = true; // Show the modal
  };

  const removeItem = () => {
    cart.update((n) => {
      delete n[name];
      return { ...n };
    });
    showModal = false; // Hide the modal after removal
  };

  const handleModalResponse = (response) => {
    if (response) {
      removeItem(); // Remove item if user confirms
    }
    showModal = false; // Hide the modal in both cases
  };
</script>

<!-- Modal for confirmation -->
{#if showModal}
  <div class="modal">
    <p>Do you want to remove the item?</p>
    <button on:click={() => handleModalResponse(true)}>Yes</button>
    <button on:click={() => handleModalResponse(false)}>No</button>
  </div>
{/if}

<div class="flex flex-col sm:flex-row">
  <img
    on:click={() => navigate(`/details/${id}`)}
    class="w-full sm:w-[45%] md:w-1/4 h-[200px] object-contain mb-2.5 sm:mr-5 sm:mb-2.5"
    src={Images}
    alt={name}
  />
  <div class="flex-grow flex flex-col">
    <h3
      class="mt-0 title cursor-pointer hover:underline"
      on:click={() => navigate(`/details/${id}`)}
    >
      {name}
    </h3>
    <p class=" text-base py-2 font-bold text-[#2488c9]">
      Cod. MePA: {MEPA ?? "Su Richiesta"}
    </p>
    <p class="my-0.5 text-sm price">
      Prezzo singolo: <b>{formatPrice(price)}</b>
    </p>
    <div class="flex mt-2.5">
      <button
        class="block m-0 text-sm shadow-md py-0.5 px-1 rounded-l-md border border-gray-500 border-r-\ focus:outline-none active:outline-none [&>*]:focus:outline-none [&>*]:active:outline-none"
        on:click={countButtonHandler}>-</button
      >
      <input
        type="number"
        class="text-center block w-10 m-0 text-sm shadow-md border border-gray-500 py-0.5 px-1 bg-gray-50 border-l-0 border-r-0"
        bind:value={count}
        on:input={handleInputChange}
        min="1"
        step="1"
      />
      <button
        class="add block m-0 text-sm shadow-md border border-gray-500 py-0.5 px-1 rounded-r-md focus:outline-none active:outline-none [&>*]:focus:outline-none [&>*]:active:outline-none"
        on:click={countButtonHandler}>+</button
      >

      <button
        class="m-0 shadow-md p-1 rounded-md flex items-center text-xs bg-red-600 text-white ml-2.5 focus:outline-none active:outline-none [&>*]:focus:outline-none [&>*]:active:outline-none"
        on:click={() => handleModalResponse(true)}
      >
        <object
          class="w-5 h-5"
          aria-label="remove"
          type="image/svg+xml"
          data="img/svg/cancel.svg"
        />
        Rimuovi
      </button>
      <p class="ml-4 text-lg price">
        <b>{formatPrice(price * count)} + Iva</b>
      </p>
    </div>
  </div>
</div>

<style>
  /* Hide HTML5 Up and Down arrows on input of type number */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }

  img:hover {
    cursor: pointer;
  }
</style>
