<script>
  // Importa un font moderno
  import 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap';
</script>

<div class="bg-white pb-12 pt-6">
<div class="content-wrapper max-w-7xl mx-auto xl:w-[80vw] 2xl:w-[90vw]">
  <div class="bg-white pb-5">
    <h1 class="text-4xl font-bold text-[#2488c9] mb-4">Elettroservizi S.r.l.</h1>
    
    <p class="text-lg mb-4">
      Siamo <strong class="font-bold">Elettroservizi S.r.l.</strong>, un'azienda che dal 1994 si occupa di impianti tecnologici, con particolare attenzione ai sistemi audio-video, elettrici, antintrusione e di videosorveglianza. Offriamo soluzioni personalizzate in base alle necessità specifiche di ogni cliente, integrando tecnologie all'avanguardia e assicurando sempre la massima qualità.
    </p>
  
    <p class="text-lg mb-4">
      Grazie al nostro team di esperti, siamo in grado di seguire ogni progetto con competenza e professionalità, completandolo in tempi rapidi e garantendo <strong class="font-bold">sicurezza</strong> e <strong class="font-bold">sostenibilità</strong>.
    </p>
  
    <p class="text-lg mb-4">
      Collaboriamo con partner di prestigio e ci impegniamo a mantenere elevati standard di certificazione e innovazione nel nostro settore.
    </p>
  
    <p class="text-lg mb-4">
      Siamo presenti su <strong class="font-bold">MePA</strong>, la piattaforma elettronica per gli acquisti della Pubblica Amministrazione, con abilitazione a un'ampia gamma di categorie merceologiche. Oltre a MePA, siamo attivi anche sulle principali piattaforme di e-procurement, garantendo così una copertura completa e la possibilità di intervenire rapidamente.
    </p>
  
    <p class="text-lg mb-8">
      Se desideri accelerare i tempi, accettiamo anche affidamenti diretti per importi inferiori alla soglia stabilita dall'attuale Codice degli Appalti, garantendo comunque soluzioni rapide e conformi alle normative.
    </p>
  
    <h2 class="text-3xl font-bold text-[#2488c9] mb-4">I NOSTRI CONTATTI</h2>
  
    <ul class="text-lg space-y-2">
      <li><strong class="font-bold">Telefono:</strong> 0696521637</li>
      <li><strong class="font-bold">E-mail:</strong> mepa@elettroservizi.it</li>
      <li><strong class="font-bold">PEC:</strong> elettroservizisrl@arubapec.it</li>
      <li><strong class="font-bold">Orari:</strong> lunedì - venerdì, 09:00 - 18:00</li>
    </ul>
  </div>
  
</div>
</div>
