<script>
  import CategorySidebar from "./CategorySidebar.svelte";
  import CardWrapper from "./CardWrapper.svelte";
  import { createEventDispatcher } from "svelte";

  let selectedCategory = "";
  let homepage = true;
  const dispatch = createEventDispatcher();

  export let showOnlyCategories = false;

  function handleCategorySelected(event) {
    selectedCategory = event.detail.categoryId;
    dispatch("categorySelected", { categoryId: selectedCategory });
  }
</script>

<div
  class="flex flex-row w-[90vw] mx-auto my-[2vh] justify-items-stretch xl:w-[80vw] 2xl:w-[90vw] align-center"
>
  {#if showOnlyCategories}
    <div class="w-full">
      <div class="text-2xl pl-2 h-9">Categorie</div>
      <CategorySidebar
        bind:showOnlyCategories
        on:categorySelected={handleCategorySelected}
      />
    </div>
  {:else}
    <!-- Aggiunta della classe sidebar-left per nascondere la sidebar sotto i 1000px -->
    <div class="sidebar-left w-2/12">
      <div class="text-2xl pl-2 h-9">Categorie</div>
      <CategorySidebar
        bind:showOnlyCategories
        on:categorySelected={handleCategorySelected}
      />
    </div>
    <div class="w-full md:pl-4 md:w-10/12">
      <div class="text-2xl pl-2 h-9 text-center sm:text-justify">Prodotti Elettroservizi</div>
      <CardWrapper {selectedCategory} {homepage} />
    </div>
  {/if}
</div>

<style>
  /* Nasconde la sidebar quando la larghezza della finestra è inferiore a 1000px */
  
</style>
