<script>
  import { Router, Route, navigate } from "svelte-routing";
  import { writable } from "svelte/store";
  import Navbar from "./components/Navbar.svelte";
  import Checkout from "./components/Checkout.svelte";
  import SearchBar from "./components/SearchBar.svelte";
  import CategoryBar from "./components/CategoryBar.svelte";
  import Contact from "./components/Contact.svelte";
  import About from "./components/About.svelte";
  import HomePage from "./components/HomePage.svelte";
  import ProductDetail from "./components/ProductDetail.svelte";
  import Footer from "./components/Footer.svelte"; // {{ edit_1 }}
  import { onDestroy, onMount } from "svelte";
  import { searchStore } from "./stores/searchStore.js";

  export let url = "";
  let searchQuery = "";
  let selectedCategoryId = null;
  let actualMap = [];
  let showOnlyCategories = false;

  const updateMap = (category) => {
    // remove all categories after the selected one
    const index = actualMap.indexOf(category);
    actualMap = actualMap.slice(0, index + 1);
    if (actualMap.length < 2) {
      searchStore.update((store) => ({
        ...store,
        categoryMap: [],
      }));
      navigate("/", { replace: true });
    } else {
      searchStore.update((store) => ({
        ...store,
        query: searchQuery,
        categoryId: selectedCategoryId,
        categoryMap: [...actualMap],
      }));
      navigate("/search", { replace: true });
    }
  };

  const changeSort = (value) => {
    console.log("ChangeSort", value);
    switch (value) {
      case "nameAZ":
        searchStore.update((n) => ({
          ...n,
          order: { value: "name", direction: "asc" },
        }));
        break;
      case "nameZA":
        searchStore.update((n) => ({
          ...n,
          order: { value: "name", direction: "desc" },
        }));
        break;
      case "priceAB":
        searchStore.update((n) => ({
          ...n,
          order: { value: "price", direction: "desc" },
        }));
        break;
      case "priceBA":
        searchStore.update((n) => ({
          ...n,
          order: { value: "price", direction: "asc" },
        }));
        break;
      default:
        console.log("Invalid sort value");
    }
  };

  // Create a custom store for the current route
  const currentRoute = writable(window.location.pathname);

  function handleCategorySelected(event) {
    searchQuery = event.detail.categoryId;
    selectedCategoryId = searchQuery;
    console.log("Category selected:", selectedCategoryId);
    navigate("/search", { replace: true });
  }

  function resetSearch() {
    console.log("Resetting search");
    searchQuery = "";
    selectedCategoryId = null;
  }

  // Update currentRoute when navigation occurs
  const updateRoute = () => {
    currentRoute.set(window.location.pathname);
  };

  const checkWindowWidth = () => {
    if (window.innerWidth > 1000) {
      showOnlyCategories = false;
    }
  };

  onMount(() => {
    const unsubscribe = searchStore.subscribe((value) => {
      searchQuery = value.query;
      selectedCategoryId = value.categoryId;
      actualMap = value.categoryMap;
    });

    window.addEventListener("popstate", updateRoute);
    window.addEventListener("resize", checkWindowWidth);

    checkWindowWidth();

    return () => {
      window.removeEventListener("popstate", updateRoute);
    };
  });

  // React to route changes
  $: if ($currentRoute === "/" || $currentRoute === "/checkout") {
    console.log("Home or Checkout detected, resetting search");
    resetSearch();
  }

  let actScroll = 0;

  addEventListener("scroll", () => {
    actScroll = window.scrollY;
  });

  onDestroy(() => {
    window.removeEventListener("resize", checkWindowWidth);
  });

  onDestroy(() => {
    unsubscribe();

    // Clean up event listener
    removeEventListener("scroll", () => {
      actScroll = window.scrollY;
    });
  });
</script>

<Router {url}>
  <Navbar bind:searchQuery bind:selectedCategoryId />
  <main>
    <div class="mt-20 p-3 pb-0">
      <div
        class="map-and-filter flex flex-row items-center mx-auto justify-items-stretch w-[90vw] xl:w-[80vw] 2xl:w-[90vw]"
      >
        <div class="category-map">
          {#each actualMap as cat, index}
            {#if index === 0}
              <span>
                {cat}
              </span>
              <span>{"> "}</span>
            {:else}
              <button
                on:click={() => {
                  searchQuery = cat;
                  selectedCategoryId = cat;
                  updateMap(cat);
                }}>{cat}</button
              >
              {#if index < actualMap.length - 1}
                <span>{"> "}</span>
              {/if}
            {/if}
          {/each}
        </div>
        <button
          class="show-categories-button"
          on:click={() => (showOnlyCategories = !showOnlyCategories)}
        >
          Categorie
        </button>
        {#if actualMap.length > 0 || !showOnlyCategories}
          <div class="filter ml-auto">
            <select
              name="sort"
              class="my-auto"
              on:change={(event) => changeSort(event.target.value)}
            >
              <option value="nameAZ">Nome: A-Z</option>
              <option value="nameZA">Nome: Z-A</option>
              <option value="priceAB">Prezzo: da Alto a Basso</option>
              <option value="priceBA">Prezzo: da Basso ad Alto</option>
            </select>
          </div>
        {/if}
      </div>
      <Route path="/search">
        {#key searchQuery || selectedCategoryId}
          <SearchBar
            {searchQuery}
            on:categorySelected={handleCategorySelected}
            bind:showOnlyCategories
          />
        {/key}
      </Route>
    </div>
    <Route path="/details/:id" let:params>
      <ProductDetail 
      bind:showOnlyCategories
      id={params.id} />
    </Route>
    <Route path="/" let:props>
      <HomePage
        {...props}
        bind:showOnlyCategories
        on:categorySelected={handleCategorySelected}
      />
    </Route>
    <Route path="/checkout" component={Checkout} />
    <Route path="/contact" component={Contact} />
    <Route path="/about" component={About} />
    <Footer />
    <!-- {{ edit_2 }} -->
  </main>
  {#if actScroll > 100}
    <button
      class="scroll-up"
      on:click={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      Torna su
    </button>
  {/if}
</Router>

<style>
  @import "./app.css";

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    margin: 5px;
  }

  button:hover {
    text-decoration: underline;
  }

  .map-and-filter {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  span {
    font-size: 1rem;
    font-weight: 600;
    margin: 5px;
  }

  .scroll-up {
    background-color: #2488c9;
    border-radius: 10px;
    bottom: 50px;
    color: white;
    cursor: pointer;
    font-size: 24px;
    padding: 10px;
    position: fixed;
    right: 50px;
    transition: 200ms;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .scroll-up:hover {
    text-decoration: none;
    background-color: #0077b6;
  }

  .show-categories-button {
    background-color: #2488c9;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    padding: 10px;
    transition: 200ms;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  @media (min-width: 768px) {
    .show-categories-button {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .map-and-filter {
      justify-content: center;
    }
    .category-map {
      display: none;
    }
  }
</style>
