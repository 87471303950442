<script>
  import { onMount } from "svelte";
  import items from "../items.js";
  import Card from "./Card.svelte";
  import { db } from "../firebase";
  import {
    collection,
    query,
    where,
    getDocs,
    limit,
    startAfter,
    getDoc,
    doc,
    orderBy,
  } from "firebase/firestore";
  import { createEventDispatcher } from "svelte";
  import CategorySidebar from "./CategorySidebar.svelte";
  import { searchStore } from "../stores/searchStore.js";

  let selectedCategory = "";
  let searchStrings = [];

  function handleCategorySelected(event) {
    searchStrings = event.detail.categoryId;
    selectedCategory = searchStrings;
    dispatch("categorySelected", { categoryId: selectedCategory });
  }

  export let searchQuery = "";
  export let selectedCategoryId = $searchStore.categoryId;
  export let showOnlyCategories = false;

  let filteredItems = [];
  let lastVisibleDocExt = null;
  let hasMoreItemsExt = true;
  let isLoading = false;
  let isLoadingfirst = false;

  const dispatch = createEventDispatcher();

  function parseSearchQuery(query) {
    const terms = [];
    const regex = /"[^"]+"|\S+/g;
    let match;
    while ((match = regex.exec(query)) !== null) {
      terms.push(match[0].replace(/"/g, ""));
    }
    return terms;
  }

  export async function searchProducts(
    searchStrings,
    categoryId,
    limitNumber = 20,
    startAfterDocId = null,
  ) {
    let productsQuery = collection(db, "Elettroservizi", "Products", "Items");
    productsQuery = query(productsQuery, limit(limitNumber));

    let results = [];
    let lastVisibleDoc;
    let hasMoreItems = false;

    if (categoryId) {
      let categoryQuery = query(
        productsQuery,
        where("category", "array-contains", categoryId),
        orderBy($searchStore.order.value, $searchStore.order.direction),
        limit(limitNumber),
      );

      if (startAfterDocId) {
        const startAfterDoc = await getDoc(
          doc(db, "Elettroservizi", "Products", "Items", startAfterDocId),
        );
        categoryQuery = query(categoryQuery, startAfter(startAfterDoc));
      }

      const categorySnapshot = await getDocs(categoryQuery);
      results = categorySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      lastVisibleDoc = categorySnapshot.docs[categorySnapshot.docs.length - 1];
      hasMoreItems = categorySnapshot.size === limitNumber;

      if (results.length >= limitNumber) {
        return { results, lastVisibleDoc, hasMoreItems };
      }
    }

    if (searchStrings.length) {
      let nameTokensQuery = query(
        productsQuery,
        where("nameTokens", "array-contains-any", searchStrings),
        orderBy($searchStore.order.value, $searchStore.order.direction),
        limit(limitNumber - results.length),
      );

      if (startAfterDocId) {
        const startAfterDoc = await getDoc(
          doc(db, "Elettroservizi", "Products", "Items", startAfterDocId),
        );
        nameTokensQuery = query(nameTokensQuery, startAfter(startAfterDoc));
      }

      const nameTokensSnapshot = await getDocs(nameTokensQuery);
      const nameTokenResults = nameTokensSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      results.push(
        ...nameTokenResults.filter(
          (doc) => !results.some((result) => result.id === doc.id),
        ),
      );
      lastVisibleDoc =
        nameTokensSnapshot.docs[nameTokensSnapshot.docs.length - 1];
      hasMoreItems = results.length >= limitNumber;

      if (results.length >= limitNumber) {
        return { results, lastVisibleDoc, hasMoreItems };
      }
    }

    if (searchStrings[0]) {
      let mepaCodeQuery = query(
        productsQuery,
        where("MEPA", "==", searchStrings[0].toUpperCase()),
        limit(limitNumber - results.length),
      );

      if (startAfterDocId) {
        const startAfterDoc = await getDoc(
          doc(db, "Elettroservizi", "Products", "Items", startAfterDocId),
        );
        mepaCodeQuery = query(mepaCodeQuery, startAfter(startAfterDoc));
      }

      const mepaCodeSnapshot = await getDocs(mepaCodeQuery);
      const mepaResults = mepaCodeSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      results.push(
        ...mepaResults.filter(
          (doc) => !results.some((result) => result.id === doc.id),
        ),
      );
      lastVisibleDoc = mepaCodeSnapshot.docs[mepaCodeSnapshot.docs.length - 1];
      hasMoreItems = results.length >= limitNumber;
    }

    return { results, lastVisibleDoc, hasMoreItems };
  }

  async function search() {
    try {
      const searchStrings = searchQuery
        ? parseSearchQuery(searchQuery.toLowerCase())
        : [];
      const { results, lastVisibleDoc, hasMoreItems } = await searchProducts(
        searchStrings,
        selectedCategoryId,
        20,
        null,
      );
      filteredItems = results;
      lastVisibleDocExt = lastVisibleDoc;
      hasMoreItemsExt = hasMoreItems;
    } finally {
      isLoading = false;
      isLoadingfirst = false;
      dispatch("searchComplete");
    }
  }

  async function loadMoreItems() {
    isLoading = true;
    const searchStrings = searchQuery
      ? parseSearchQuery(searchQuery.toLowerCase())
      : [];
    const { results, lastVisibleDoc, hasMoreItems } = await searchProducts(
      searchStrings,
      selectedCategoryId,
      20,
      lastVisibleDocExt?.id,
    );
    filteredItems = [...filteredItems, ...results];
    lastVisibleDocExt = lastVisibleDoc;
    hasMoreItemsExt = hasMoreItems;
    isLoading = false;
  }

  onMount(() => {
    isLoadingfirst = true;
    const unsubscribe = searchStore.subscribe((value) => {
      searchQuery = value.query;
      selectedCategoryId = value.categoryId;
      search();
    });
  });
</script>

<div
  class="flex flex-row w-[90vw] mx-auto my-[2vh] xl:w-[80vw] 2xl:w-[90vw] align-center"
>
  {#if showOnlyCategories}
    <div class="w-full">
      <CategorySidebar
        on:categorySelected={handleCategorySelected}
        bind:showOnlyCategories
      />
    </div>
  {:else}
    <div class="sidebar-left w-2/12">
      <div class="text-2xl pl-2 h-9">Categorie</div>
      <CategorySidebar on:categorySelected={handleCategorySelected} />
    </div>
    <div class="w-full md:pl-4 md:w-10/12">
      <div
        class="grid justify-items-center md:justify-items-stretch 
                xl:grid-cols-3 
                lg:grid-cols-3 
                md:grid-cols-2 
                sm:grid-cols-2
                grid-cols-1"
      >
        {#if filteredItems.length === 0 && !isLoadingfirst}
          <div class="text-center text-gray-500 w-full">
            Nessun prodotto corrisponde alla ricerca.
          </div>
        {:else if isLoadingfirst}
          {#each Array(8) as _}
            <div
              class="flex flex-col justify-between max-w-sm p-6 bg-gray-200 border border-gray-300 shadow rounded-lg animate-pulse"
            >
              <div class="w-full h-48 bg-gray-300 rounded-t-lg"></div>
              <div class="mt-4 space-y-2">
                <div class="h-6 bg-gray-300 rounded w-3/4"></div>
                <div class="h-6 bg-gray-300 rounded w-2/4"></div>
                <div class="h-8 bg-gray-300 rounded w-1/2"></div>
              </div>
            </div>
          {/each}
        {:else}
          {#each filteredItems as item (item.id)}
            <Card {item} />
          {/each}
        {/if}
      </div>
      {#if hasMoreItemsExt && filteredItems.length > 0}
        <div class="flex justify-center mt-4 w-full mx-auto">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-10 flex items-center"
            on:click={loadMoreItems}
            disabled={isLoading}
          >
            {#if isLoading}
              <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8H4zm2 5.292l3.292 3.292a8 8 0 0011.416 0l3.292-3.292H6z"
                ></path>
              </svg>
              Caricamento...
            {:else}
              Carica altri
            {/if}
          </button>
        </div>
      {/if}
    </div>
  {/if}
</div>

