<script>
  import CategoryCard from "./CategoryCard.svelte";
  import MenuCard from "./CategoryCard.svelte";

  export let elements = [
    { name: "test1", image: "test1", category: "" },
    { name: "test2", image: "test2", category: "" },
    { name: "test3", image: "test3", category: "" },
    { name: "test4", image: "test4", category: "" },
    { name: "test5", image: "test5", category: "" },
    { name: "test6", image: "test6", category: "" },
    { name: "test7", image: "test7", category: "" },
    { name: "test8", image: "test8", category: "" },
    { name: "test9", image: "test9", category: "" },
    { name: "test10", image: "test10", category: "" },
  ];

  let currentIndex = 0;

  function next() {
    if (currentIndex < elements.length - 1) {
      currentIndex++;
    }
  }

  function prev() {
    if (currentIndex > 0) {
      currentIndex--;
    }
  }
</script>

<section class="w-full mx-auto mb-[2vh] mt-[34px] grid justify-items-stretch gap-1 p-[10px] md:gap-3
                md:grid-cols-5 
                sm:grid-cols-4 
                grid-cols-3">
      {#each elements as { name, image, category }}
          <CategoryCard {name} {image} {category} />
      {/each}
</section>
