<script>
  import { onMount } from "svelte";
  import items from "../items.js";
  import { cart } from "../stores/stores.js";
  import { db } from "../firebase";
  import { getDoc, doc } from "firebase/firestore";
  import CategorySidebar from "./CategorySidebar.svelte";
  import { createEventDispatcher } from "svelte";
  import {
    collection,
    query,
    where,
    getDocs,
    limit,
    startAfter,
    Query,
  } from "firebase/firestore";

  let selectedCategory = "";
  const dispatch = createEventDispatcher();

  function handleCategorySelected(event) {
    selectedCategory = event.detail.categoryId;
    dispatch("categorySelected", { categoryId: selectedCategory });
  }

  export let id;
  let Images;
  let name;
  let price;
  let description;
  let category;
  let code;
  let MEPA;
  let item = {};
  let count;
  let number = 1;
  let isLoading = true; // Add loading state
  export let showOnlyCategories = false;


  export async function getProductById(id) {
    try {
      const productQuery = query(
        collection(db, "Elettroservizi", "Products", "Items"),
        where("id", "==", id), // Query documents where the "id" field matches the provided id
      );
      const productSnapshot = await getDocs(productQuery); // Use getDocs to retrieve the matching documents

      if (!productSnapshot.empty) {
        // Check if any documents match
        const productDoc = productSnapshot.docs[0]; // Get the first document (assuming IDs are unique)
        return { id: productDoc.id, ...productDoc.data() }; // Return the document ID and its data
      } else {
        console.error(`No product found with ID: ${id}`);
        return null;
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      return null;
    }
  }

  function formatPrice(priceString) {
    const priceNumber = parseFloat(priceString);
    const formattedPrice = new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(priceNumber);

    // Sposta il simbolo dell'euro prima del numero
    return "€" + formattedPrice.replace("€", "").trim();
  }

  onMount(async () => {
    //item = items.find(it => it.id == id);
    item = await getProductById(id);
    Images = item.Images;
    name = item.name;
    price = formatPrice(item.price);
    description = item.description;
    category = item.category;
    code = item.code;
    MEPA = item.MEPA;
    count = item.count;
    isLoading = false;

    console.log(count);
  });

  const checkout = () => {
    checkedOut = true;
    cart.update((n) => {
      return {};
    });
  };

  const countButtonHandler = (e) => {
    if (e.target.classList.contains("add")) {
      number++;
    } else if (number >= 1) {
      number--;
    }
    cart.update((n) => ({ ...n, [name]: { ...n[name], count } }));
  };

  const removeItem = () => {
    cart.update((n) => {
      delete n[name];
      return n;
    });
  };

  function addToCart(event) {
    event.stopPropagation(); // Prevent the event from bubbling up to the card
    cart.update((n) => {
      return { ...n, [name]: { ...item, count: number } };
    });
    count = number;
  }
</script>

<div
  class="flex flex-row w-[90vw] mx-auto my-[2vh] justify-items-stretch xl:w-[80vw] 2xl:w-[90vw]"
>
{#if showOnlyCategories}
    <div class="w-full">
      <div class="text-2xl pl-2 h-9">Categorie</div>
      <CategorySidebar
        bind:showOnlyCategories
        on:categorySelected={handleCategorySelected}
      />
    </div>
  {:else}
  <div class="sidebar-left w-2/12">
    <div class="text-2xl pl-2 h-9">Categorie</div>
    <CategorySidebar
    bind:showOnlyCategories
    on:categorySelected={handleCategorySelected} />
  </div>
  <!-- CardWrapper -->
  <div class="w-full md:pl-4 md:w-10/12">
    {#if isLoading}
      <!-- Check if loading -->
      <div class="max-w-4xl mx-auto my-10 p-6 bg-gray-200">
        <!-- Grey box for loading -->
        <div class="flex flex-col md:flex-row items-stretch md:items-start">
          <div class="w-full md:w-1/2 mb-4 md:mb-0 md:mr-6">
            <div class="w-full h-64 bg-gray-300 rounded-lg"></div>
            <!-- Placeholder for image -->
          </div>
          <div class="w-full md:w-1/2">
            <div class="h-8 bg-gray-300 mb-2 rounded"></div>
            <!-- Placeholder for title -->
            <div class="h-4 bg-gray-300 mb-2 rounded"></div>
            <!-- Placeholder for MEPA -->
            <div class="h-4 bg-gray-300 mb-2 rounded"></div>
            <!-- Placeholder for description -->
            <div class="h-6 bg-gray-300 mb-2 rounded"></div>
            <!-- Placeholder for price -->
            <div class="flex mt-4">
              <div class="h-8 bg-gray-300 w-12 rounded-l-md"></div>
              <!-- Placeholder for count button -->
              <div class="h-8 bg-gray-300 w-12 mx-1 rounded"></div>
              <!-- Placeholder for count display -->
              <div class="h-8 bg-gray-300 w-12 rounded-r-md"></div>
              <!-- Placeholder for count button -->
            </div>
            <div class="h-8 bg-gray-300 mt-6 rounded"></div>
            <!-- Placeholder for add to cart button -->
          </div>
        </div>
      </div>
    {:else if item}
      <div class="px-2 py-2 font-bold text-4xl text-center">
        {name}
      </div>

      <div class="max-w-4xl mx-auto my-10 p-6 bg-white">
        <div class="flex flex-col md:flex-row items-stretch md:items-start">
          <div class="w-full md:w-1/2 mb-4 md:mb-0 md:mr-6">
            <img
              class="w-full h-auto object-cover rounded-lg"
              src={Images}
              alt={name}
            />
          </div>
          <div class="w-full md:w-1/2">
            <div class="flex items-center relative">
              <div class="flex items-center relative">
                <h3
                  class="text-lg font-bold text-[#2488c9] cursor-pointer hover:text-blue-600"
                  on:click={() => {
                    navigator.clipboard.writeText(MEPA ?? "Su Richiesta");
                    document.querySelector(".copied-text").style.display =
                      "block";
                    setTimeout(
                      () =>
                        (document.querySelector(".copied-text").style.display =
                          "none"),
                      1000,
                    );
                  }}
                >
                  Cod. MePA: {MEPA == null || MEPA == "" || MEPA == undefined
                    ? "Da Inserire*"
                    : MEPA}
                </h3>
                {#if MEPA == null || MEPA == "" || MEPA == undefined}
                  <img
                    src="/img/svg/copy-icon.svg"
                    alt="Copy"
                    class="ml-2 w-5 h-5 cursor-pointer hover:opacity-75"
                    on:click={() => {
                      navigator.clipboard.writeText(MEPA ?? "Su Richiesta");
                      document.querySelector(".copied-text").style.display =
                        "block";
                      setTimeout(
                        () =>
                          (document.querySelector(
                            ".copied-text",
                          ).style.display = "none"),
                        1000,
                      );
                    }}
                  />
                {/if}
                <div
                  class="absolute top-[-23px] left-0 bg-green-500 text-white text-xs px-2 py-1 rounded copied-text"
                  style="display: none;"
                >
                  Copiato
                </div>
              </div>
            </div>
            <h3 class="text-lg font-normal text-gray-900">
              {@html description || "Nessuna descrizione disponibile"}
            </h3>
            <div class="text-xl font-bold text-gray-800 mt-2">
              {price}
              <span class="text-sm text-gray-600 font-normal">IVA Esclusa</span>
            </div>
            <div class="flex mt-4">
              <button
                class="block m-0 text-sm shadow-md py-0.5 px-1 rounded-l-md border border-gray-500 border-r-1 focus:outline-none active:outline-none [&>*]:focus:outline-none [&>*]:active:outline-none"
                on:click={countButtonHandler}>-</button
              >
              <p
                class="block m-0 text-sm shadow-md border border-gray-500 py-0.5 px-1 bg-gray-50 border-l-0 border-r-0"
              >
                {number}
              </p>
              <button
                class="add block m-0 text-sm shadow-md border border-gray-500 py-0.5 px-1 rounded-r-md focus:outline-none active:outline-none [&>*]:focus:outline-none [&>*]:active:outline-none"
                on:click={countButtonHandler}>+</button
              >
            </div>
            <button
              class="flex items-center justify-center w-full py-1 mt-6 text-sm bg-gradient-to-r font-semibold from-[#2488c9] to-blue-500 text-white rounded-lg shadow-md hover:from-blue-600 hover:to-indigo-700 transition-transform transform hover:scale-105 sm:self-stretch disabled:bg-gray-400 disabled:cursor-not-allowed duration-200"
              on:click={addToCart}>Aggiungi al Preventivo</button
            >
            {#if MEPA == "" || MEPA == null || MEPA == undefined}
              <div class="text-md font-bold text-red-900 mt-2">
                *Il Codice MePA sarà comunicato in fase d'ordine
              </div>
            {/if}
            {#if count}
              <div class="text-md font-bold text-green-900 mt-2">
                {count} elemento a preventivo
              </div>
            {/if}
          </div>
        </div>
      </div>
    {:else}
      <p class="text-center text-gray-500 mt-10">Loading...</p>
    {/if}
  </div>
  {/if}
</div>
